import {useDispatch, useSelector} from "react-redux";
import {selectSelectedReport, setSelectedReport, setSelectedReportStatus} from "../../redux/slice/reportingSlice";
import React, {useEffect, useState} from "react";
import {
    Avatar,
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Chip,
    Divider,
    Image, Link, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader,
    Progress, Textarea,
    Tooltip, useDisclosure
} from "@nextui-org/react";
import {
    AvenueIcon, CalendarIcon,
    DateAndTimeIcon, GroupOfPeopleIcon,
    LocationIcon,
    OfflineIcon,
    OnlineIcon,
    ScopeIcon,
    TagIcon
} from "../../Icons/Icons";
import {IconWrapperSize14} from "../../Icons/IconWrapper";
import IncomeCard from "../../../assets/images/financeCards/incomeCard.png";
import ExpenseCard from "../../../assets/images/financeCards/expenseCard.png";
import ProfitCard from "../../../assets/images/financeCards/profitCard.png";
import LossCard from "../../../assets/images/financeCards/lossCard.png";
import Carousel from "../carousel/Carousel";
import {selectAllUserData} from "../../redux/slice/userSlice";
import {addComment} from "../../../services/ProjectsServices";
import {useNavigate} from "react-router-dom";
import {ToastContainer} from "react-toastify";
import BundledEditor from "../../../BundledEditor";
import {getImageFromDrive} from "../../common/DetailsViewHelpers";

export default function ViewReportProject() {
    const selectedReport = useSelector(selectSelectedReport);
    const user = useSelector(selectAllUserData);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [feedbackMessage, setFeedbackMessage] = useState("");

    const {isOpen, onOpen, onOpenChange} = useDisclosure();

    const totalMembers =
        (selectedReport.attendance?.homeClubActiveMembersInfo?.length > 0 ? selectedReport.attendance.homeClubActiveMembersInfo.length : 0) +
        (selectedReport.attendance?.homeClubGuestMembersInfo?.length > 0 ? selectedReport.attendance.homeClubGuestMembersInfo.length : 0) +
        (selectedReport.attendance?.dcmsInfo?.length > 0 ? selectedReport.attendance.dcmsInfo.length : 0) +
        (selectedReport.attendance?.rotarians !== null ? selectedReport.attendance.rotarians : 0) +
        (selectedReport.attendance?.otherGuests !== null ? selectedReport.attendance.otherGuests : 0) +
        (selectedReport.attendance?.alumnus !== null ? selectedReport.attendance.alumnus : 0) +
        (selectedReport.attendance?.otherClubMembers !== null ? selectedReport.attendance.otherClubMembers : 0) +
        (selectedReport.attendance?.otherPis !== null ? selectedReport.attendance.otherPis : 0) +
        (selectedReport.attendance?.otherDistrictRotaractors !== null ? selectedReport.attendance.otherDistrictRotaractors : 0) +
        (selectedReport.attendance?.interactors !== null ? selectedReport.attendance.interactors : 0);

    useEffect(() => {
        if (window.location.pathname.toString().includes("/view-report") && (null === selectedReport || undefined === selectedReport)) {
            window.opener = null;
            window.open("", "_self");
            window.close();
        }

    }, []);

    const projectBasicDetailsData = [
        {
            title: "Venue",
            dynamicData: selectedReport.venue,
            icon: <IconWrapperSize14 className="bg-danger/10 text-danger">
                <LocationIcon className="text-danger" fill="currentColor" size={40}/>
            </IconWrapperSize14>
        },
        {
            title: "Start Date & Time",
            dynamicData: new Date(selectedReport.startDate.replaceAll("T", " ")).toLocaleString(),
            icon: <IconWrapperSize14 className="bg-primary/10 text-primary">
                <DateAndTimeIcon className="text-primary" fill="currentColor" size={40}/>
            </IconWrapperSize14>
        },
        {
            title: "End Date & Time",
            dynamicData: new Date(selectedReport.endDate.replaceAll("T", " ")).toLocaleString(),
            icon: <IconWrapperSize14 className="bg-warning/10 text-warning">
                <DateAndTimeIcon className="text-warning" fill="currentColor" size={40}/>
            </IconWrapperSize14>
        },
        {
            title: "Primary Avenue",
            dynamicData: selectedReport.avenueMain,
            icon: <IconWrapperSize14 className="bg-success/10 text-success">
                <AvenueIcon className="text-success" fill="currentColor" size={40}/>
            </IconWrapperSize14>
        },
        {
            title: "Secondary Avenue",
            dynamicData: selectedReport?.avenueOptional ? selectedReport.avenueOptional : "-",
            icon: <IconWrapperSize14 className="bg-secondary/10 text-secondary">
                <AvenueIcon className="text-secondary" fill="currentColor" size={40}/>
            </IconWrapperSize14>
        },
        {
            title: "Project Mode",
            dynamicData: selectedReport.projectMode,
            icon: <IconWrapperSize14 className="bg-primary/10 text-primary">
                {selectedReport.projectMode === "Online"
                    ? <OnlineIcon className="text-primary" fill="currentColor" size={40}/>
                    : <OfflineIcon className="text-primary" fill="currentColor" size={40}/>}
            </IconWrapperSize14>
        },
        {
            title: "District Thrust Area Category",
            dynamicData: selectedReport.thrustAreaType,
            icon: <IconWrapperSize14 className="bg-danger/10 text-danger">
                <ScopeIcon className="text-danger" fill="currentColor" size={40}/>
            </IconWrapperSize14>
        },
        {
            title: "Reporting Month & Quarter",
            dynamicData: selectedReport.adminData.reportingMonth + " , " + selectedReport.adminData.reportingQuarter,
            icon: <IconWrapperSize14 className="bg-warning/10 text-warning">
                <CalendarIcon className="text-warning" fill="currentColor" size={40}/>
            </IconWrapperSize14>
        },
    ];

    const primaryFinancesData = [
        {
            title: "Income",
            amount: selectedReport.eventFinances.income,
            imgSrc: IncomeCard
        },
        {
            title: "Expense",
            amount: selectedReport.eventFinances.expense,
            imgSrc: ExpenseCard
        },
        {
            title: "Profit",
            amount: selectedReport.eventFinances.profit,
            imgSrc: ProfitCard
        },
        {
            title: "Loss",
            amount: selectedReport.eventFinances.loss,
            imgSrc: LossCard
        }
    ];

    const jointFinancesData = [
        {
            title: "Overall Income",
            amount: selectedReport.eventFinances?.jointEventFinances?.income,
            imgSrc: IncomeCard
        },
        {
            title: "Overall Expense",
            amount: selectedReport.eventFinances?.jointEventFinances?.expense,
            imgSrc: ExpenseCard
        },
        {
            title: "Overall Profit",
            amount: selectedReport.eventFinances?.jointEventFinances?.profit,
            imgSrc: ProfitCard
        },
        {
            title: "Overall Loss",
            amount: selectedReport.eventFinances?.jointEventFinances?.loss,
            imgSrc: LossCard
        }
    ];

    function closeProjectReport() {
        dispatch(setSelectedReport({
            report: null,
            reportType: null
        }));
        dispatch(setSelectedReportStatus("idle"));
        window.opener = null;
        window.open("", "_self");
        window.close();
    }

    function rteDisabled(value) {
        return (<BundledEditor
            name="formData"
            value={value}
            disabled={true}
            init={{
                min_height: 100,
                menubar: false,
                toolbar: false,
                paste_data_images: false,
                plugins: ["wordcount"],
                content_style:
                    "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
            }}
        />);
    }

    return (
        <>
            <ToastContainer/>

            <Card>
                <CardHeader className="flex gap-3">
                    <div className="flex flex-col">
                        <p className="text-xl">{selectedReport.name}</p>
                        <p className="text-small text-default-500"> Conducted by {selectedReport.adminData.clubName}</p>
                        <div className="flex h-5 items-center space-x-2 text-small mt-3">
                            <Chip className="capitalize px-4"
                                  color="primary"
                                  size="sm"
                                  variant="flat"
                                  startContent={<TagIcon size={28}/>}
                            >
                                {selectedReport.projectId}
                            </Chip>
                            <Divider orientation="vertical"/>
                            <Chip className="capitalize px-4"
                                  color={"warning"}
                                  size="sm"
                                  variant="dot"
                                  startContent={<TagIcon size={28}/>}
                            >
                                {selectedReport.adminData.reportStatus}
                            </Chip>
                        </div>
                    </div>
                </CardHeader>
                <Divider/>
                <CardBody>
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
                        {projectBasicDetailsData.map((item, index) =>
                            <Card key={index} shadow="sm">
                                <CardBody className="flex flex-row gap-x-2 items-center">
                                    {item.icon}
                                    <div className="flex flex-col">
                                        <p className="text-md font-medium">{item.dynamicData}</p>
                                        <p className="text-sm text-default-500">{item.title}</p>
                                    </div>
                                </CardBody>
                            </Card>
                        )}
                    </div>
                    <div className="col-span-full mt-10 px-2">
                        <h2 className="text-xl font-medium mb-2">Project Details</h2>

                        <div className="flex flex-col">
                            <h2 className="text-lg text-default-700 font-medium ">Aim :</h2>
                            {rteDisabled(selectedReport.projectDescription.aim)}
                            {/*<Markup content={selectedReport.projectDescription.aim} className="pl-4 reportTable"/>*/}
                        </div>

                        <div className="p-5">
                            <Divider/>
                        </div>

                        <div className="flex flex-col">
                            <h2 className="text-lg text-default-700 font-medium ">Project Groundwork :</h2>
                            {rteDisabled(selectedReport.projectDescription.groundWork)}
                            {/*<Markup content={selectedReport.projectDescription.groundWork}*/}
                            {/*        className="pl-4 reportTable"/>*/}
                        </div>

                        <div className="p-5">
                            <Divider/>
                        </div>

                        <div className="flex flex-col">
                            <h2 className="text-lg text-default-700 font-medium ">Summary :</h2>
                            {rteDisabled(selectedReport.projectDescription.summary)}
                            {/*<Markup content={selectedReport.projectDescription.summary} className="pl-4 reportTable"/>*/}
                        </div>
                        <div className="p-5">
                            <Divider/>
                        </div>
                        {selectedReport.projectDescription?.feedBacks && <div className="flex flex-col">
                            <h2 className="text-lg text-default-700 font-medium ">Feedbacks :</h2>
                            <Carousel data={selectedReport.projectDescription.feedBacks}/>
                        </div>}

                        {selectedReport?.interactions?.feedBacks.length > 0 && <div className="flex flex-col">
                            <h2 className="mt-10 text-lg text-default-700 font-medium ">Council Feedbacks :</h2>
                            <Carousel data={selectedReport.interactions.feedBacks}/>
                        </div>}

                    </div>
                    <div className="mt-6 px-2">
                        <h2 className="text-xl font-medium mb-2">To View the
                            <Link isBlock isExternal showAnchorIcon
                                  className="text-xl font-medium"
                                  href={selectedReport.supportDocuments.supportDocumentsURL}
                                  color="secondary">
                                Supporting Documents
                            </Link>
                        </h2>
                    </div>

                    <div className="col-span-full mt-10 px-2">
                        <h2 className="text-xl font-medium mb-2">{selectedReport.isJointProject
                            ? "Home Club's Project Finances" : "Project Finances"}</h2>
                        <div
                            className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-x-8 gap-y-4 items-center">
                            {
                                primaryFinancesData.map(item => <Card key={item.title}>
                                    <CardHeader className="absolute z-10 top-1 flex-col !items-start">
                                        <h4 className="text-white font-medium text-2xl">₹ {item.amount}</h4>
                                        <p className="text-sm text-white/60 uppercase font-bold">{item.title}</p>
                                    </CardHeader>
                                    <Image
                                        removeWrapper
                                        alt="Income Card background"
                                        className="z-0 w-full h-full object-cover"
                                        src={item.imgSrc}
                                    />
                                </Card>)
                            }
                        </div>
                    </div>

                    {selectedReport.isJointProject && <div className="col-span-full mt-10 px-2">
                        <h2 className="text-xl font-medium mb-2">Overall Joint Meeting Finances</h2>
                        <div
                            className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-x-8 gap-y-4 items-center">
                            {
                                jointFinancesData.map(item => <Card key={item.title}>
                                    <CardHeader className="absolute z-10 top-1 flex-col !items-start">
                                        <h4 className="text-white font-medium text-2xl">₹ {item.amount}</h4>
                                        <p className="text-sm text-white/60 uppercase font-bold">{item.title}</p>
                                    </CardHeader>
                                    <Image
                                        removeWrapper
                                        alt="Card background"
                                        className="z-0 w-full h-full object-cover"
                                        src={item.imgSrc}
                                    />
                                </Card>)
                            }
                        </div>
                    </div>}

                    <div className="flex flex-col col-span-full mt-10 px-2">
                        <h2 className="text-xl font-medium mb-2">Attendance</h2>
                        <div
                            className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 items-center">
                            <Card shadow="sm" isHoverable className="col-span-full">
                                <CardBody>
                                    <div className="flex justify-end">
                                        <GroupOfPeopleIcon className="text-default" fill="currentColor" size={30}/>
                                    </div>
                                    <div className="flex flex-row gap-2 flex-wrap mb-2">
                                        {selectedReport.attendance.homeClubActiveMembersInfo.map((data, index) =>
                                            <Tooltip key={index} content={
                                                <div className="px-1 py-2">
                                                    <div className="text-small font-bold">{data.name}</div>
                                                    <div className="text-tiny">{data.pranaliId}</div>
                                                </div>
                                            }>
                                                <Chip
                                                    variant="bordered"
                                                    // color="secondary"
                                                    avatar={<Avatar
                                                        name={data.name}
                                                        getInitials={(name) => name.charAt(0)}
                                                        src={getImageFromDrive(data.profilePhotoUrl)}
                                                    />}>
                                                    {data.name}
                                                </Chip>
                                            </Tooltip>
                                        )}
                                    </div>
                                    <p className="text-sm text-default-500">Home Club Active Members</p>

                                    <Progress
                                        aria-label="Downloading..."
                                        size="sm"
                                        value={(selectedReport.attendance.homeClubActiveMembersInfo.length / totalMembers) * 100}
                                        color="secondary"
                                        className="mt-4"
                                    />
                                </CardBody>
                            </Card>
                            {selectedReport?.attendance?.homeClubGuestMembersInfo.length > 0 &&
                                <Card shadow="sm" isHoverable className="col-span-full">
                                    <CardBody>
                                        <div className="flex justify-end">
                                            <GroupOfPeopleIcon className="text-default" fill="currentColor"
                                                               size={30}/>
                                        </div>
                                        <div className="flex flex-row gap-2 flex-wrap mb-2">
                                            {selectedReport.attendance.homeClubGuestMembersInfo.map((data, index) =>
                                                <Tooltip key={index} content={
                                                    <div className="px-1 py-2">
                                                        <div className="text-small font-bold">{data.name}</div>
                                                    </div>
                                                }>
                                                    <Chip

                                                        variant="bordered"
                                                        // color="secondary"
                                                        avatar={<Avatar
                                                            name={data.name}
                                                            getInitials={(name) => name.charAt(0)}
                                                            src={getImageFromDrive(data.profilePhotoUrl)}
                                                        />}>
                                                        {data.name}
                                                    </Chip>
                                                </Tooltip>
                                            )}
                                        </div>
                                        <p className="text-sm text-default-500">Home Club Guest Members</p>

                                        <Progress
                                            aria-label="Downloading..."
                                            size="sm"
                                            value={(selectedReport.attendance.homeClubGuestMembersInfo.length / totalMembers) * 100}
                                            color="secondary"
                                            className="mt-4"
                                        />
                                    </CardBody>
                                </Card>
                            }
                            {selectedReport?.attendance?.dcmsInfo.length > 0 &&
                                <Card shadow="sm" isHoverable className="col-span-full">
                                    <CardBody>
                                        <div className="flex justify-end">
                                            <GroupOfPeopleIcon className="text-default" fill="currentColor"
                                                               size={30}/>
                                        </div>
                                        <div className="flex flex-row gap-2 flex-wrap mb-2">
                                            {selectedReport.attendance.dcmsInfo.map((data, index) =>
                                                <Tooltip key={index} content={
                                                    <div className="px-1 py-2">
                                                        <div className="text-small font-bold">{data.name}</div>
                                                        <div className="text-tiny">{data.pranaliId}</div>
                                                    </div>
                                                }>
                                                    <Chip
                                                        variant="bordered"
                                                        // color="secondary"
                                                        avatar={<Avatar
                                                            name={data.name}
                                                            getInitials={(name) => name.charAt(0)}
                                                            src={getImageFromDrive(data.profilePhotoUrl)}
                                                        />}>
                                                        {data.name}
                                                    </Chip>
                                                </Tooltip>
                                            )}
                                        </div>
                                        <p className="text-sm text-default-500">District Council Members</p>

                                        <Progress
                                            aria-label="Downloading..."
                                            size="sm"
                                            value={(selectedReport.attendance.dcmsInfo.length / totalMembers) * 100}
                                            color="secondary"
                                            className="mt-4"
                                        />
                                    </CardBody>
                                </Card>
                            }
                            <Card shadow="sm" isHoverable>
                                <CardBody>
                                    <div className="flex justify-end">
                                        <GroupOfPeopleIcon className="text-default" fill="currentColor" size={30}/>
                                    </div>
                                    <div className="flex flex-col">
                                        <p className="text-lg font-medium">{selectedReport.attendance.rotarians}</p>
                                        <p className="text-sm text-default-500">Rotarians</p>
                                    </div>
                                    <Progress
                                        aria-label="Downloading..."
                                        size="sm"
                                        value={(selectedReport.attendance.rotarians / totalMembers) * 100}
                                        color="secondary"
                                        className="mt-4"
                                    />
                                </CardBody>
                            </Card>
                            <Card shadow="sm" isHoverable>
                                <CardBody>
                                    <div className="flex justify-end">
                                        <GroupOfPeopleIcon className="text-default" fill="currentColor" size={30}/>
                                    </div>
                                    <div className="flex flex-col">
                                        <p className="text-lg font-medium">{selectedReport.attendance.otherGuests}</p>
                                        <p className="text-sm text-default-500">Other Guests</p>
                                    </div>
                                    <Progress
                                        aria-label="Downloading..."
                                        size="sm"
                                        value={(selectedReport.attendance.otherGuests / totalMembers) * 100}
                                        color="secondary"
                                        className="mt-4"
                                    />
                                </CardBody>
                            </Card>
                            <Card shadow="sm" isHoverable>
                                <CardBody>
                                    <div className="flex justify-end">
                                        <GroupOfPeopleIcon className="text-default" fill="currentColor" size={30}/>
                                    </div>
                                    <div className="flex flex-col">
                                        <p className="text-lg font-medium">{selectedReport.attendance.alumnus}</p>
                                        <p className="text-sm text-default-500">Alumnus</p>
                                    </div>
                                    <Progress
                                        aria-label="Downloading..."
                                        size="sm"
                                        value={(selectedReport.attendance.alumnus / totalMembers) * 100}
                                        color="secondary"
                                        className="mt-4"
                                    />
                                </CardBody>
                            </Card>
                            <Card shadow="sm" isHoverable>
                                <CardBody>
                                    <div className="flex justify-end">
                                        <GroupOfPeopleIcon className="text-default" fill="currentColor" size={30}/>
                                    </div>
                                    <div className="flex flex-col">
                                        <p className="text-lg font-medium">{selectedReport.attendance.otherClubMembers}</p>
                                        <p className="text-sm text-default-500">Other Club Members</p>
                                    </div>
                                    <Progress
                                        aria-label="Downloading..."
                                        size="sm"
                                        value={(selectedReport.attendance.otherClubMembers / totalMembers) * 100}
                                        color="secondary"
                                        className="mt-4"
                                    />
                                </CardBody>
                            </Card>
                            <Card shadow="sm" isHoverable>
                                <CardBody>
                                    <div className="flex justify-end">
                                        <GroupOfPeopleIcon className="text-default" fill="currentColor" size={30}/>
                                    </div>
                                    <div className="flex flex-col">
                                        <p className="text-lg font-medium">{selectedReport.attendance.otherPis}</p>
                                        <p className="text-sm text-default-500">Other PIS</p>
                                    </div>
                                    <Progress
                                        aria-label="Downloading..."
                                        size="sm"
                                        value={(selectedReport.attendance.otherPis / totalMembers) * 100}
                                        color="secondary"
                                        className="mt-4"
                                    />
                                </CardBody>
                            </Card>
                            <Card shadow="sm" isHoverable>
                                <CardBody>
                                    <div className="flex justify-end">
                                        <GroupOfPeopleIcon className="text-default" fill="currentColor" size={30}/>
                                    </div>
                                    <div className="flex flex-col">
                                        <p className="text-lg font-medium">{selectedReport.attendance.otherDistrictRotaractors}</p>
                                        <p className="text-sm text-default-500">Other District Rotaractors</p>
                                    </div>
                                    <Progress
                                        aria-label="Downloading..."
                                        size="sm"
                                        value={(selectedReport.attendance.otherDistrictRotaractors / totalMembers) * 100}
                                        color="secondary"
                                        className="mt-4"
                                    />
                                </CardBody>
                            </Card>
                            <Card shadow="sm" isHoverable>
                                <CardBody>
                                    <div className="flex justify-end">
                                        <GroupOfPeopleIcon className="text-default" fill="currentColor" size={30}/>
                                    </div>
                                    <div className="flex flex-col">
                                        <p className="text-lg font-medium">{selectedReport.attendance.interactors}</p>
                                        <p className="text-sm text-default-500">Interactors</p>
                                    </div>
                                    <Progress
                                        aria-label="Downloading..."
                                        size="sm"
                                        value={(selectedReport.attendance.interactors / totalMembers) * 100}
                                        color="secondary"
                                        className="mt-4"
                                    />
                                </CardBody>
                            </Card>
                            <Card shadow="sm" isHoverable>
                                <CardBody>
                                    <div className="flex justify-end">
                                        <GroupOfPeopleIcon className="text-default" fill="currentColor" size={30}/>
                                    </div>
                                    <div className="flex flex-col">
                                        <p className="text-lg font-medium">{totalMembers}</p>
                                        <p className="text-sm text-default-500">Total</p>
                                    </div>
                                    <Progress
                                        aria-label="Downloading..."
                                        size="sm"
                                        value={100}
                                        color="secondary"
                                        className="mt-4"
                                    />
                                </CardBody>
                            </Card>
                        </div>
                    </div>

                    {selectedReport.isJointProject && selectedReport.jointEventClubs &&
                        <div className="flex flex-col col-span-full">
                            <h2 className="text-xl font-medium mb-1">Joint Project Clubs</h2>
                            <div className="flex flex-row gap-2 flex-wrap">
                                {selectedReport.jointEventClubs.map((data, index) =>
                                    <Chip
                                        key={index}
                                        variant="bordered"
                                        color="primary"
                                        radius="sm"
                                    >
                                        {data.name}
                                    </Chip>
                                )}
                            </div>
                        </div>
                    }
                </CardBody>
                <CardFooter className="flex gap-4 justify-end">
                    <Button type="button" color="default" variant="faded" onClick={() => closeProjectReport()}>
                        Close
                    </Button>
                    {
                        user?.councilData?.councilStatus === "Active" &&
                        <Button type="button" color="primary" variant="flat" onClick={() => onOpen()}>
                            Add Council Feedback
                        </Button>
                    }
                </CardFooter>
            </Card>

            <Modal
                isOpen={isOpen}
                backdrop="blur"
                isDismissable={false}
                placement="auto"
                onOpenChange={onOpenChange}>
                <ModalContent>
                    {(onClose) => (
                        <>
                            <ModalHeader className="flex flex-col gap-1">Provide feedback
                                to {selectedReport.projectId} </ModalHeader>
                            <ModalBody>
                                <p className="text-small text-default-600 mb-2">
                                    Hello Rtr.{user.name}, <br/> Please provide your valuable feedback for project
                                    "{selectedReport.name}".
                                </p>
                                <Textarea
                                    isRequired
                                    minRows={4}
                                    key="feedbackMessage"
                                    variant="faded"
                                    label="Feedback Message"
                                    labelPlacement="outside"
                                    placeholder="Enter your feedback"
                                    value={feedbackMessage}
                                    onValueChange={setFeedbackMessage}
                                />
                            </ModalBody>
                            <ModalFooter>
                                <Button color="danger" variant="light" onPress={onClose}>
                                    Cancel
                                </Button>
                                <Button color="primary" isDisabled={!feedbackMessage}
                                        onClick={() => {
                                            addComment(dispatch, user, selectedReport.projectId, feedbackMessage, navigate)
                                        }}>
                                    Submit
                                </Button>
                            </ModalFooter>
                        </>
                    )}
                </ModalContent>
            </Modal>
        </>
    );
}