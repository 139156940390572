import thankYou from "../../assets/images/thankYou.png";

export default function ThankYouPage() {
    return (
        <div className="flex justify-center">
            <div className="w-3/4">
                <div className="flex gap-3 justify-center">
                    <img className="mt-15" src={thankYou} alt="Thank You" width="300"/>
                </div>
                <p className="mb-3"> for submitting your application for Council 57 at Rotaract District 3142. We've
                    received your form and will be in touch soon.</p>
                <p>If you have any questions, feel free to reach out to DRR Rtn. Rtr. Nisha Raju
                    (rtr.nisharaju@gmail.com)
                    or DRS Rtr. Rohit Deshpande (rtr.rohitd@gmail.com)</p>
                <p className="mt-5">Best Regards,<br/>
                    Rotaract District 3142</p>
            </div>
        </div>
    );
}