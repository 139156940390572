import React, {useEffect} from "react";
import {Button} from "@nextui-org/react";
import {useDispatch, useSelector} from "react-redux";
import {selectAllUserData} from "../../redux/slice/userSlice";
import MembershipTable from "./MembershipTable";
import {ToastContainer} from "react-toastify";
import {Add, Refresh} from "../../Icons/SystemIcons";
import {useNavigate} from "react-router-dom";
import {getMembershipData} from "../../../services/MembershipService";
import {ONLY_PRES_SEC_AND_IT} from "../../../constants/WebPageConstants";

export default function Membership() {
    const user = useSelector(selectAllUserData);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        getMembershipData(dispatch, user);
    }, []);

    return (
        <div className="flex flex-col gap-4 sm:gap-8">
            <ToastContainer/>
            <div className="flex justify-around gap-4 sm:gap-8 items-center">
                <div className="relative">
                    <h1 className="text-2xl font-medium">Membership</h1>
                </div>

                <div className="flex flex-col order-2 col-span-4 md:order-last md:col-span-4">
                    <div className="flex justify-end items-center">
                        {ONLY_PRES_SEC_AND_IT.includes(user.pranaliAccess) &&
                            <Button className="hidden sm:flex mr-2" type="button" color="success" variant="ghost"
                                    startContent={<Add fill="currentColor" size={28}/>}
                                    onClick={() => navigate("/pranali/membership/new")}>
                                New Member
                            </Button>}
                        {ONLY_PRES_SEC_AND_IT.includes(user.pranaliAccess) &&
                            <Button className="mr-2 sm:hidden" type="button" isIconOnly color="success" variant="ghost"
                                    onClick={() => navigate("/pranali/membership/new")}>
                                <Add fill="currentColor" size={28}/>
                            </Button>}
                        <Button type="button" isIconOnly color="default" variant="ghost"
                                onClick={() => getMembershipData(dispatch, user)}>
                            <Refresh fill="currentColor" size={28}/>
                        </Button>
                    </div>
                </div>
            </div>
            <MembershipTable/>
        </div>
    );
}