import {useSelector} from "react-redux";
import {getClubListFetchStatus, selectClubsList} from "../../redux/slice/clubDataSlice";
import React, {useState} from "react";
import {COLUMNS_CLUBS_IN_DISTRICT} from "../../../constants/tableColumns";
import {
    Button,
    Dropdown, DropdownItem,
    DropdownMenu,
    DropdownTrigger,
    Input, Pagination,
    Spinner, Table, TableBody, TableCell, TableColumn, TableHeader, TableRow,
    Tooltip
} from "@nextui-org/react";
import {EditIcon, EyeIcon} from "../../Icons/Icons";
import {PRES_SEC_AND_IT_TEAM} from "../../../constants/WebPageConstants";
import {SearchIcon} from "../../Icons/SearchIcon";
import {ChevronDownIcon} from "../../Icons/ChevronDownIcon";
import {capitalize} from "../Finances/utils";
import {selectAllUserData} from "../../redux/slice/userSlice";

export default function ClubTable() {
    const user = useSelector(selectAllUserData);
    const clubList = useSelector(selectClubsList);
    const clubListFetchStatus = useSelector(getClubListFetchStatus);

    const INITIAL_VISIBLE_COLUMNS = ["clubCode", "name", "zone", "type", "charterDate", "actions"];
    const [filterValue, setFilterValue] = useState("");
    const [selectedKeys, setSelectedKeys] = useState(new Set([]));
    const [visibleColumns, setVisibleColumns] = useState(new Set(INITIAL_VISIBLE_COLUMNS));
    const [rowsPerPage, setRowsPerPage] = useState(100);
    const [sortDescriptor, setSortDescriptor] = useState({
        column: "clubName",
        direction: "descending",
    });

    const [page, setPage] = useState(1);

    const hasSearchFilter = Boolean(filterValue);

    const headerColumns = React.useMemo(() => {
        if (visibleColumns === "all") return COLUMNS_CLUBS_IN_DISTRICT;

        return COLUMNS_CLUBS_IN_DISTRICT.filter((column) => Array.from(visibleColumns).includes(column.uid));
    }, [visibleColumns]);

    const filteredItems = React.useMemo(() => {
        let filteredClubList = [...clubList];

        if (hasSearchFilter) {
            filteredClubList = filteredClubList.filter(records =>
                records.clubName.toLowerCase().includes(filterValue.toLowerCase()),
            );
        }


        return filteredClubList;
    }, [clubList, filterValue]);

    const pages = Math.ceil(filteredItems.length / rowsPerPage);

    const items = React.useMemo(() => {
        const start = (page - 1) * rowsPerPage;
        const end = start + rowsPerPage;

        return filteredItems.slice(start, end);
    }, [page, filteredItems, rowsPerPage]);

    const sortedItems = React.useMemo(() => {
        return [...items].sort((a, b) => {

            let first = a[sortDescriptor.column];
            let second = b[sortDescriptor.column];

            const cmp = first < second ? -1 : first > second ? 1 : 0;

            return sortDescriptor.direction === "descending" ? -cmp : cmp;
        });
    }, [sortDescriptor, items]);

    const renderCell = React.useCallback((record, columnKey) => {
        let cellValue = record[columnKey];

        switch (columnKey) {
            case "charterDate":
                return (<p className="text-bold text-small capitalize">{
                        new Date(record.charterDate).toLocaleDateString()}</p>
                );
            case "actions":
                return (
                    <div className="relative flex items-center gap-2">
                        <Tooltip content="Details">
                            <span className="text-lg text-primary-400 cursor-pointer active:opacity-50"
                                // onClick={_ =>
                                // getMoMById(dispatch, user, record["clubName"], "view").then(() =>
                                //     window.open('/pranali/meetings/view-report', "_blank", "noreferrer"))}
                            >
                                <EyeIcon/>
                             </span>
                        </Tooltip>
                        {(PRES_SEC_AND_IT_TEAM.includes(user.pranaliAccess) && record.reportStatus === "Draft") &&
                            <Tooltip content="Edit report">
                                <span className="text-lg text-secondary-400 cursor-pointer active:opacity-50"
                                    // onClick={() =>
                                    //     getMoMById(dispatch, user, record["clubName"], "edit").then(() =>
                                    //         navigate("/pranali/meetings/edit-report"))}
                                >
                                    <EditIcon/>
                                </span>
                            </Tooltip>
                        }
                    </div>
                );
            default:
                return cellValue;
        }
    }, []);

    const onNextPage = React.useCallback(() => {
        if (page < pages) {
            setPage(page + 1);
        }
    }, [page, pages]);

    const onPreviousPage = React.useCallback(() => {
        if (page > 1) {
            setPage(page - 1);
        }
    }, [page]);

    const onRowsPerPageChange = React.useCallback((e) => {
        setRowsPerPage(Number(e.target.value));
        setPage(1);
    }, []);

    const onSearchChange = React.useCallback((value) => {
        if (value) {
            setFilterValue(value);
            setPage(1);
        } else {
            setFilterValue("");
        }
    }, []);

    const onClear = React.useCallback(() => {
        setFilterValue("")
        setPage(1)
    }, []);

    const topContent = React.useMemo(() => {
        return (
            <div className="flex flex-col gap-4">
                <div className="grid grid-cols-6 md:grid-cols-12 justify-between gap-3">
                    <Input
                        isClearable
                        className="col-span-6 md:col-span-4 w-full"
                        placeholder="Search by club name..."
                        startContent={<SearchIcon/>}
                        value={filterValue}
                        onClear={() => onClear()}
                        onValueChange={onSearchChange}
                    />
                    <div className="col-span-6 md:col-span-8 flex flex-wrap gap-3 justify-end">
                        <Dropdown>
                            <DropdownTrigger>
                                <Button endContent={<ChevronDownIcon className="text-small"/>} variant="flat">
                                    Columns
                                </Button>
                            </DropdownTrigger>
                            <DropdownMenu
                                disallowEmptySelection
                                aria-label="Table Columns"
                                closeOnSelect={false}
                                selectedKeys={visibleColumns}
                                selectionMode="multiple"
                                onSelectionChange={setVisibleColumns}
                            >
                                {COLUMNS_CLUBS_IN_DISTRICT.map(column => (
                                    <DropdownItem key={column.uid} className="capitalize">
                                        {capitalize(column.name)}
                                    </DropdownItem>
                                ))}
                            </DropdownMenu>
                        </Dropdown>
                    </div>
                </div>
                <div className="flex justify-between items-center">
                    <span className="text-default-400 text-small">Total {clubList.length} records</span>
                    <label className="flex items-center text-default-400 text-small">
                        Rows per page:
                        <select
                            className="bg-transparent outline-none text-default-400 text-small"
                            onChange={onRowsPerPageChange}
                        >
                            <option value="15">15</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                        </select>
                    </label>
                </div>
            </div>
        );
    }, [
        filterValue,
        clubList,
        visibleColumns,
        onRowsPerPageChange,
        clubList.length,
        onSearchChange,
        hasSearchFilter,
    ]);

    const bottomContent = React.useMemo(() => {
        return (
            <div className="py-2 px-2 flex justify-between items-center">
                <Pagination
                    isCompact
                    showControls
                    showShadow
                    color="primary"
                    page={page}
                    total={pages}
                    onChange={setPage}
                />
                <div className="hidden sm:flex w-[30%] justify-end gap-2">
                    <Button isDisabled={pages === 1} size="sm" variant="flat" onPress={onPreviousPage}>
                        Previous
                    </Button>
                    <Button isDisabled={pages === 1} size="sm" variant="flat" onPress={onNextPage}>
                        Next
                    </Button>
                </div>
            </div>
        );
    }, [selectedKeys, items.length, page, pages, hasSearchFilter]);

    return (
        <Table
            aria-label="Example table with custom cells, pagination and sorting"
            classNames={{
                wrapper: "min-h-[500px]",
            }}
            isHeaderSticky
            bottomContent={bottomContent}
            bottomContentPlacement="outside"
            selectedKeys={selectedKeys}
            sortDescriptor={sortDescriptor}
            topContent={topContent}
            topContentPlacement="outside"
            onSelectionChange={setSelectedKeys}
            onSortChange={setSortDescriptor}
        >
            <TableHeader columns={headerColumns}>
                {column => (
                    <TableColumn key={column.uid} align={column.uid === "actions" ? "center" : "start"}
                                 allowsSorting={column.sortable}>
                        {column.name}
                    </TableColumn>
                )}
            </TableHeader>
            <TableBody
                emptyContent={clubListFetchStatus === "complete" ? "No record found" : null}
                isLoading={clubListFetchStatus === 'pending'}
                items={sortedItems}
                loadingContent={clubListFetchStatus !== "complete" ? <Spinner label="Loading..."/> : null}
            >
                {item => <TableRow key={item.name}>
                    {columnKey => <TableCell>{renderCell(item, columnKey)}</TableCell>}
                </TableRow>}
            </TableBody>
        </Table>

    );
}