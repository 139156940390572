import ApiEndpoint from "./ApiEndpoint";
import {
    setMinutesOfMeetings,
    setMinutesOfMeetingsStatus,
    setMoMForAmbassadorial, setMoMForAmbassadorialStatus, setNewReportStatus,
    setSelectedReport,
    setSelectedReportStatus
} from "../components/redux/slice/reportingSlice";
import axios from "axios";
import {throwErrorToast} from "../components/admin/aaras/NewNomination";
import {toast} from "react-toastify";
import {toastOptions} from "../constants/WebPageConstants";
import React from "react";
import {toastMessageForSuccess} from "../components/common/ToastMessages";
import {getClubDataById} from "../components/admin/dashboard/Dashboard";

export function getMeetingsList(dispatch, user) {
    const apiEndPoint = new ApiEndpoint();
    dispatch(setMinutesOfMeetingsStatus("pending"));
    dispatch(setMinutesOfMeetings([]));

    axios(apiEndPoint.getMeetingsList(user))
        .then(res => {
            if (res.status === 200) {
                dispatch(setMinutesOfMeetings(res.data.reverse()));
                dispatch(setMinutesOfMeetingsStatus("complete"));
            }
        })
        .catch(e => {
            console.log(e)
            dispatch(setMinutesOfMeetingsStatus("complete"));
            throwErrorToast(e);
        });
}

export function getMoMListForAmbassadorial(dispatch, user, clubCode) {
    const apiEndPoint = new ApiEndpoint();
    dispatch(setMoMForAmbassadorialStatus("pending"));
    dispatch(setMoMForAmbassadorial([]));

    axios(apiEndPoint.getMoMListForAmbassadorial(user, clubCode)).then(res => {
        if (res.status === 200) {
            dispatch(setMoMForAmbassadorialStatus("complete"));
            dispatch(setMoMForAmbassadorial(res.data.reverse()));
        }
        return res;
    }).catch(e => {
        dispatch(setMoMForAmbassadorialStatus("complete"));
        throwErrorToast(e);
    });
}

export function getMoMById(dispatch, user, projectId, purpose) {
    const apiEndPoint = new ApiEndpoint();
    dispatch(setSelectedReportStatus("pending"));
    dispatch(setSelectedReport({
        report: [],
        reportType: "Meeting"
    }));

    return axios(apiEndPoint.getMoMById(user, projectId, purpose)).then(res => {
        if (res.status === 200) {
            dispatch(setSelectedReport({
                report: res.data,
                reportType: "Meeting"
            }));
            dispatch(setSelectedReportStatus("complete"));
        }
        return res;
    }).catch(e => {
        dispatch(setSelectedReportStatus("complete"));
        throwErrorToast(e);
    });
}

export function addMinutesOfMeeting(dispatch, user, report, navigate) {
    const apiEndPoint = new ApiEndpoint();
    dispatch(setNewReportStatus("pending"));

    return axios(apiEndPoint.addMinutesOfMeeting(user, report))
        .then(res => {
            if (res.status === 204) {
                getMeetingsList(dispatch, user);
                getClubDataById(dispatch, user);
                toastMessageForSuccess(report.isDraft, "", "Meeting");
                dispatch(setNewReportStatus("complete"));
                setTimeout(navigate(".."), 2500);
            }
            return res;
        })
        .catch(e => {
            console.log(e);
            dispatch(setNewReportStatus("complete"));
            throwErrorToast(e);
        });
}

export function updateMeetingForClub(dispatch, user, report, meetingId, navigate) {
    const apiEndPoint = new ApiEndpoint();
    dispatch(setSelectedReportStatus("pending"));

    function clearTaskAndCloseMeetingsScreen() {
        dispatch(setSelectedReportStatus("idle"));
        dispatch(setSelectedReport({
            report: null,
            reportType: "Meeting"
        }));
        navigate("..");
    }

    return axios(apiEndPoint.updateMeetingForClub(user, meetingId, report))
        .then(res => {
            if (res.status === 204) {
                getMeetingsList(dispatch, user);
                getClubDataById(dispatch, user);
                toastMessageForSuccess(report.isDraft, "", "Meeting");
                setTimeout(clearTaskAndCloseMeetingsScreen, 2500);
            }
            return res;
        })
        .catch(e => {
            dispatch(setSelectedReportStatus("complete"));
            throwErrorToast(e);
        });
}

export function deleteMoMReport(dispatch, user, meetingId, records) {
    const apiEndPoint = new ApiEndpoint();
    dispatch(setSelectedReportStatus("pending"));

    let recordIndex = records.findIndex(data => data["meetingId"] === meetingId)
    let tempSelectedItem = JSON.parse(JSON.stringify(records[recordIndex]));
    tempSelectedItem.reportStatus = "Deleted";

    return axios(apiEndPoint.deleteMoMReport(user, meetingId))
        .then(res => {
            if (res.status === 204) {
                // TO SET DATA ON COPY OF ARRAY LIST AT GIVEN INDEX
                let tempRecords = records.with(recordIndex, tempSelectedItem);
                dispatch(setMinutesOfMeetings(tempRecords));
                dispatch(setSelectedReportStatus("complete"));
                toast.success(<p className="text-medium">Meeting Deleted successfully</p>, toastOptions);
            }
            return res;
        })
        .catch(e => {
            dispatch(setSelectedReportStatus("complete"));
            throwErrorToast(e);
        });
}