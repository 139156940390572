import {useDispatch, useSelector} from "react-redux";
import {selectAllUserData} from "../../redux/slice/userSlice";
import {selectClubsList} from "../../redux/slice/clubDataSlice";
import {
    getProjectReportsStatus,
    selectProjectList,
} from "../../redux/slice/reportingSlice";
import {useNavigate} from "react-router-dom";
import {
    Button,
    Checkbox,
    Chip,
    Dropdown, DropdownItem, DropdownMenu,
    DropdownTrigger,
    Input, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, Pagination,
    Select,
    SelectItem, Spinner, Table, TableBody, TableCell, TableColumn, TableHeader, TableRow,
    Tooltip,
    useDisclosure
} from "@nextui-org/react";
import React, {useState} from "react";
import {COLUMNS_PROJECTS} from "../../../constants/tableColumns";
import {COLOR_MAP_REPORT_STATUS} from "../../common/TableCommonMethodsAndHelpers";
import {DeleteIcon, EditIcon, EyeIcon} from "../../Icons/Icons";
import {ONLY_PRES_SEC, PRES_SEC_AND_IT_TEAM} from "../../../constants/WebPageConstants";
import {SearchIcon} from "../../Icons/SearchIcon";
import {ChevronDownIcon} from "../../Icons/ChevronDownIcon";
import {capitalize} from "../Finances/utils";
import {deleteProjectReport, getProjectById} from "../../../services/ProjectsServices";

export default function ProjectsTable() {
    const dispatch = useDispatch();
    const user = useSelector(selectAllUserData);
    const clubsList = useSelector(selectClubsList);
    const projects = useSelector(selectProjectList);
    const projectStatus = useSelector(getProjectReportsStatus);
    const navigate = useNavigate();

    const {isOpen, onOpen, onClose, onOpenChange} = useDisclosure();

    const [selectedProjectReport, setSelectedProjectReport] = useState(null);

    const INITIAL_VISIBLE_COLUMNS = ["projectId", "name", "reportStatus", "avenueMain", "avenueOptional", "clubName", "actions"];
    const [filterValue, setFilterValue] = useState("");
    const [selectedKeys, setSelectedKeys] = useState(new Set([]));
    const [visibleColumns, setVisibleColumns] = useState(new Set(INITIAL_VISIBLE_COLUMNS));
    const [isSelected, setIsSelected] = useState(true);
    const [clubNameFilter, setClubNameFilter] = useState("all");
    const [rowsPerPage, setRowsPerPage] = useState(15);
    const [sortDescriptor, setSortDescriptor] = useState({
        column: "projectId",
        direction: "descending",
    });

    const [page, setPage] = useState(1);

    const hasSearchFilter = Boolean(filterValue);

    const headerColumns = React.useMemo(() => {
        if (visibleColumns === "all") return COLUMNS_PROJECTS;

        return COLUMNS_PROJECTS.filter((column) => Array.from(visibleColumns).includes(column.uid));
    }, [visibleColumns]);

    const filteredItems = React.useMemo(() => {
        let filteredProjRecords = [...projects];

        if (hasSearchFilter) {
            filteredProjRecords = filteredProjRecords.filter(records =>
                records.projectId.toLowerCase().includes(filterValue.toLowerCase()),
            );
        }
        if (clubNameFilter !== "all" && Array.from(clubNameFilter).length !== clubsList.length) {
            filteredProjRecords = filteredProjRecords.filter(records =>
                Array.from(clubNameFilter).includes(records.clubName),
            );
        }

        return filteredProjRecords;
    }, [projects, filterValue, clubNameFilter]);

    const pages = Math.ceil(filteredItems.length / rowsPerPage);

    const items = React.useMemo(() => {
        const start = (page - 1) * rowsPerPage;
        const end = start + rowsPerPage;

        return filteredItems.slice(start, end);
    }, [page, filteredItems, rowsPerPage]);

    const sortedItems = React.useMemo(() => {
        return [...items].sort((a, b) => {
            let first = a[sortDescriptor.column];
            let second = b[sortDescriptor.column];

            const cmp = first < second ? -1 : first > second ? 1 : 0;

            return sortDescriptor.direction === "descending" ? -cmp : cmp;
        });
    }, [sortDescriptor, items]);

    const renderCell = React.useCallback((record, columnKey) => {
        let cellValue = record[columnKey];

        switch (columnKey) {
            case "reportStatus":
                return (
                    <Chip className="capitalize"
                          color={COLOR_MAP_REPORT_STATUS[record.reportStatus.replace("-", "")]} size="sm"
                          variant="dot">
                        {cellValue}
                    </Chip>
                );
            case "avenueOptional":
                return (
                    <p className="text-bold text-small capitalize">{null === record?.avenueOptional || undefined === record?.avenueOptional ? "" : record.avenueOptional}</p>
                );
            case "actions":
                return (
                    <div className="relative flex items-center gap-2">
                        <Tooltip content="Details">
                            <span className="text-lg text-primary-400 cursor-pointer active:opacity-50"
                                  onClick={() =>
                                      getProjectById(dispatch, user, record["projectId"], "view").then(() =>
                                          window.open('/pranali/projects/view-report', "_blank", "noreferrer")
                                      )
                                  }>
                                <EyeIcon/>
                             </span>
                        </Tooltip>
                        {(PRES_SEC_AND_IT_TEAM.includes(user.pranaliAccess) && record.reportStatus === "Draft") &&
                            <Tooltip content="Edit report">
                                <span className="text-lg text-secondary-400 cursor-pointer active:opacity-50"
                                      onClick={() =>
                                          getProjectById(dispatch, user, record["projectId"], "edit").then(() =>
                                              navigate("/pranali/projects/edit-report"))
                                      }>
                                    <EditIcon/>
                                </span>
                            </Tooltip>
                        }
                        {(PRES_SEC_AND_IT_TEAM.includes(user.pranaliAccess) && record.reportStatus !== "Deleted") &&
                            <Tooltip color="danger" content="Delete report">
                                <span className="text-lg text-danger cursor-pointer active:opacity-50"
                                      onClick={() => {
                                          setSelectedProjectReport(record);
                                          onOpen();
                                      }}>
                                    <DeleteIcon/>
                                </span>
                            </Tooltip>
                        }
                    </div>
                );
            default:
                return cellValue;
        }
    }, []);

    const onNextPage = React.useCallback(() => {
        if (page < pages) {
            setPage(page + 1);
        }
    }, [page, pages]);

    const onPreviousPage = React.useCallback(() => {
        if (page > 1) {
            setPage(page - 1);
        }
    }, [page]);

    const onRowsPerPageChange = React.useCallback((e) => {
        setRowsPerPage(Number(e.target.value));
        setPage(1);
    }, []);

    const onSearchChange = React.useCallback((value) => {
        if (value) {
            setFilterValue(value);
            setPage(1);
        } else {
            setFilterValue("");
        }
    }, []);

    const onClear = React.useCallback(() => {
        setFilterValue("")
        setPage(1)
    }, []);

    const topContent = React.useMemo(() => {
        return (
            <div className="flex flex-col gap-4">
                <div className="grid grid-cols-6 md:grid-cols-12 justify-between gap-3">
                    <Input
                        isClearable
                        className="col-span-6 md:col-span-4 w-full"
                        placeholder="Search by meeting id..."
                        startContent={<SearchIcon/>}
                        value={filterValue}
                        onClear={() => onClear()}
                        onValueChange={onSearchChange}
                    />
                    <div className="col-span-6 md:col-span-8 flex flex-wrap gap-3 justify-end">
                        {!ONLY_PRES_SEC.includes(user.pranaliAccess) &&
                            <>
                                <Select
                                    items={clubsList}
                                    selectionMode="multiple"
                                    label="Selected Club's"
                                    placeholder="Select a club"
                                    labelPlacement="inside"
                                    selectedKeys={clubNameFilter}
                                    onSelectionChange={setClubNameFilter}
                                    className="max-w-xs sm:min-w-sm sm:max-w-md">
                                    {clubDataListItems => (
                                        <SelectItem key={clubDataListItems.name} textValue={clubDataListItems.name}>
                                            <div className="flex gap-2 items-center">
                                                <div className="flex flex-col">
                                                    <span className="text-small">{clubDataListItems.name}</span>
                                                    <span
                                                        className="text-tiny text-default-400">Zone {clubDataListItems.zone}</span>
                                                </div>
                                            </div>
                                        </SelectItem>
                                    )}
                                </Select>
                                <Checkbox isSelected={isSelected}
                                          onValueChange={(value) => {
                                              setIsSelected(value);
                                              if (!value)
                                                  setClubNameFilter([]);
                                              else {
                                                  let tempList = [];
                                                  clubsList.forEach(club => tempList.push(club.name));
                                                  setClubNameFilter(tempList);
                                              }
                                          }}> Select All Club</Checkbox>
                            </>
                        }
                        <Dropdown>
                            <DropdownTrigger>
                                <Button endContent={<ChevronDownIcon className="text-small"/>} variant="flat">
                                    Columns
                                </Button>
                            </DropdownTrigger>
                            <DropdownMenu
                                disallowEmptySelection
                                aria-label="Table Columns"
                                closeOnSelect={false}
                                selectedKeys={visibleColumns}
                                selectionMode="multiple"
                                onSelectionChange={setVisibleColumns}
                            >
                                {COLUMNS_PROJECTS.map(column => (
                                    <DropdownItem key={column.uid} className="capitalize">
                                        {capitalize(column.name)}
                                    </DropdownItem>
                                ))}
                            </DropdownMenu>
                        </Dropdown>
                    </div>
                </div>
                <div className="flex justify-between items-center">
                    <span className="text-default-400 text-small">Total {projects.length} records</span>
                    <label className="flex items-center text-default-400 text-small">
                        Rows per page:
                        <select
                            className="bg-transparent outline-none text-default-400 text-small"
                            onChange={onRowsPerPageChange}
                        >
                            <option value="15">15</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                        </select>
                    </label>
                </div>
            </div>
        );
    }, [
        filterValue,
        clubNameFilter,
        projects,
        visibleColumns,
        onRowsPerPageChange,
        projects.length,
        onSearchChange,
        hasSearchFilter,
    ]);

    const bottomContent = React.useMemo(() => {
        return (
            <div className="py-2 px-2 flex justify-between items-center">
                <Pagination
                    isCompact
                    showControls
                    showShadow
                    color="primary"
                    page={page}
                    total={pages}
                    onChange={setPage}
                />
                <div className="hidden sm:flex w-[30%] justify-end gap-2">
                    <Button isDisabled={pages === 1} size="sm" variant="flat" onPress={onPreviousPage}>
                        Previous
                    </Button>
                    <Button isDisabled={pages === 1} size="sm" variant="flat" onPress={onNextPage}>
                        Next
                    </Button>
                </div>
            </div>
        );
    }, [selectedKeys, items.length, page, pages, hasSearchFilter]);

    return (
        <>
            <Table
                aria-label="Example table with custom cells, pagination and sorting"
                classNames={{
                    wrapper: "min-h-[500px]",
                }}
                isHeaderSticky
                bottomContent={bottomContent}
                bottomContentPlacement="outside"
                selectedKeys={selectedKeys}
                sortDescriptor={sortDescriptor}
                topContent={topContent}
                topContentPlacement="outside"
                onSelectionChange={setSelectedKeys}
                onSortChange={setSortDescriptor}
            >
                <TableHeader columns={headerColumns}>
                    {column => (
                        <TableColumn key={column.uid} align={column.uid === "actions" ? "center" : "start"}
                                     allowsSorting={column.sortable}>
                            {column.name}
                        </TableColumn>
                    )}
                </TableHeader>
                <TableBody
                    emptyContent={projectStatus === "complete" ? "No record found" : null}
                    isLoading={projectStatus === 'pending'}
                    items={sortedItems}
                    loadingContent={projectStatus !== "complete" ? <Spinner label="Loading..."/> : null}
                >
                    {item => <TableRow key={item.projectId}>
                        {columnKey => <TableCell>{renderCell(item, columnKey)}</TableCell>}
                    </TableRow>}
                </TableBody>
            </Table>

            <Modal
                isOpen={isOpen}
                backdrop="blur"
                placement="auto"
                scrollBehavior="inside"
                onOpenChange={onOpenChange}>
                <ModalContent>
                    {() => (
                        <>
                            <ModalHeader>
                                Delete {selectedProjectReport.projectId}
                            </ModalHeader>
                            <ModalBody>
                                <p>Are you sure you want to delete project
                                    report {selectedProjectReport.projectId} ?</p>
                                <p>Once a report is deleted it cannot be reverted back.</p>
                            </ModalBody>
                            <ModalFooter>
                                <Button color="default" variant="light" onPress={onClose}>
                                    Close
                                </Button>
                                <Button color="danger"
                                        onPress={() => {
                                            deleteProjectReport(dispatch, user, selectedProjectReport.projectId, projects);
                                            onClose();
                                        }}>
                                    Delete
                                </Button>
                            </ModalFooter>
                        </>
                    )}
                </ModalContent>
            </Modal>
        </>
    );
}