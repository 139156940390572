import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import {GoogleOAuthProvider} from "@react-oauth/google";
import {Provider} from "react-redux";
import store from './components/redux/store';
import './assets/fonts/Inter-VariableFont.ttf';

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <GoogleOAuthProvider clientId="756495051238-po29f1hscq7j6h9b6qhv85r5kmb19t39.apps.googleusercontent.com">
        {/*<React.StrictMode>*/}
        <Provider store={store}>
            <main className="light text-foreground bg-background min-h-screen">
                <App/>
            </main>
        </Provider>
        {/*</React.StrictMode>*/}
    </GoogleOAuthProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();


