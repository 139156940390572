import ApiEndpoint from "./ApiEndpoint";
import {
    setNewReportStatus,
    setProjects, setProjectsForAmbassadorial,
    setProjectsForAmbassadorialStatus,
    setProjectsStatusAndIsNomination, setSelectedReport, setSelectedReportStatus,
} from "../components/redux/slice/reportingSlice";
import axios from "axios";
import {throwErrorToast} from "../components/admin/aaras/NewNomination";
import {toastMessageForSuccess} from "../components/common/ToastMessages";
import {toast} from "react-toastify";
import {toastOptions} from "../constants/WebPageConstants";
import React from "react";
import {getClubDataById} from "../components/admin/dashboard/Dashboard";

export function getProjectsList(dispatch, user, isForNominations) {
    const apiEndPoint = new ApiEndpoint();
    dispatch(setProjectsStatusAndIsNomination({projectsStatus: "pending", isForNominations: isForNominations}));
    dispatch(setProjects([]));

    axios(apiEndPoint.getProjectsList(user, isForNominations)).then(res => {
        if (res.status === 200) {
            dispatch(setProjectsStatusAndIsNomination({
                projectsStatus: "complete",
                isForNominations: isForNominations
            }));
            dispatch(setProjects(res.data.reverse()));
        }
        return res;
    }).catch(e => {
        dispatch(setProjectsStatusAndIsNomination({projectsStatus: "complete", isForNominations: isForNominations}));
        throwErrorToast(e);
    });
}

export function getProjectsListForAmbassadorial(dispatch, user, clubCode) {
    const apiEndPoint = new ApiEndpoint();
    dispatch(setProjectsForAmbassadorialStatus("pending"));
    dispatch(setProjectsForAmbassadorial([]));
    axios(apiEndPoint.getProjectsListForAmbassadorial(user, clubCode)).then(res => {
        if (res.status === 200) {
            dispatch(setProjectsForAmbassadorialStatus("complete"));
            dispatch(setProjectsForAmbassadorial(res.data.reverse()));
        }
        return res;
    }).catch(e => {
        dispatch(setProjectsForAmbassadorialStatus("complete"));
        throwErrorToast(e);
    });
}

export function getProjectById(dispatch, user, projectId, purpose) {
    const apiEndPoint = new ApiEndpoint();
    dispatch(setSelectedReportStatus("pending"));
    dispatch(setSelectedReport({
        report: null,
        reportType: "Project"
    }));

    return axios(apiEndPoint.getProjectById(user, projectId, purpose)).then(res => {
        if (res.status === 200) {
            dispatch(setSelectedReport({
                report: res.data,
                reportType: "Project"
            }));
            dispatch(setSelectedReportStatus("complete"));
        }
        return res;
    }).catch(e => {
        dispatch(setSelectedReportStatus("complete"));
        throwErrorToast(e);
    });
}

export function addProjectReport(dispatch, user, report, navigate) {
    const apiEndPoint = new ApiEndpoint();
    dispatch(setNewReportStatus("pending"));

    axios(apiEndPoint.addProjectReport(user, report))
        .then(res => {
            if (res.status === 204) {
                getProjectsList(dispatch, user, false);
                getClubDataById(dispatch, user);
                toastMessageForSuccess(report.isDraft, "", "Project");
                dispatch(setNewReportStatus("complete"));
                setTimeout(navigate(".."), 2500);
            }
            return res;
        })
        .catch(e => {
            console.log(e)
            dispatch(setNewReportStatus("complete"));
            throwErrorToast(e);
        });
}

export function updateProjectReport(dispatch, user, report, projectId, navigate) {
    const apiEndPoint = new ApiEndpoint();
    dispatch(setSelectedReportStatus("pending"));

    function clearTaskAndCloseMeetingsScreen() {
        dispatch(setSelectedReportStatus("idle"));
        dispatch(setSelectedReport({
            report: null,
            reportType: "Project"
        }));
        navigate("..");
    }

    axios(apiEndPoint.updateProjectReport(user, projectId, report))
        .then(res => {
            if (res.status === 204) {
                getProjectsList(dispatch, user, false);
                getClubDataById(dispatch, user);
                toastMessageForSuccess(report.isDraft, "", "Project");
                setTimeout(clearTaskAndCloseMeetingsScreen, 2500);
            }
            return res;
        })
        .catch(e => {
            dispatch(setSelectedReportStatus("complete"));
            throwErrorToast(e);
        });
}

export function addComment(dispatch, user, projectId, feedback, navigate) {
    const apiEndPoint = new ApiEndpoint();

    function clearTaskAndCloseMeetingsScreen() {
        dispatch(setSelectedReportStatus("idle"));
        dispatch(setSelectedReport({
            report: null,
            reportType: "Project"
        }));
        navigate("..");
    }

    axios(apiEndPoint.addComment(user, projectId, feedback))
        .then(res => {
            if (res.status === 204) {
                toast.success(<p className="text-medium">Feedback to project added successfully</p>, toastOptions);
                setTimeout(clearTaskAndCloseMeetingsScreen, 2500);
            }
            return res;
        })
        .catch(e => throwErrorToast(e));
}

export function deleteProjectReport(dispatch, user, projectId, records) {
    const apiEndPoint = new ApiEndpoint();
    dispatch(setSelectedReportStatus("pending"));

    let recordIndex = records.findIndex(data => data["projectId"] === projectId)
    let tempSelectedItem = JSON.parse(JSON.stringify(records[recordIndex]));
    tempSelectedItem.reportStatus = "Deleted";

    return axios(apiEndPoint.deleteProjectReport(user, projectId))
        .then(res => {
            if (res.status === 204) {
                // TO SET DATA ON COPY OF ARRAY LIST AT GIVEN INDEX
                let tempRecords = records.with(recordIndex, tempSelectedItem);
                dispatch(setProjects(tempRecords));
                dispatch(setSelectedReportStatus("complete"));
                getClubDataById(dispatch, user);
                toast.success(<p className="text-medium">Project Deleted successfully</p>, toastOptions);
            }
            return res;
        })
        .catch(e => {
            dispatch(setSelectedReportStatus("complete"));
            throwErrorToast(e);
        });
}
