import React from "react";
import {COLUMNS_MEMBERSHIP} from "../../../constants/tableColumns";
import {useDispatch, useSelector} from "react-redux";
import {
    selectMembershipData,
    getMembershipDataStatus
} from "../../redux/slice/membershipSlice";
import {selectClubsList} from "../../redux/slice/clubDataSlice";
import {
    Button, Checkbox,
    Chip,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownTrigger,
    Input, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader,
    Pagination, Progress,
    Select,
    SelectItem,
    Table,
    TableBody,
    TableCell,
    TableColumn,
    TableHeader,
    TableRow,
    Tooltip, useDisclosure,
} from "@nextui-org/react";
import {SearchIcon} from "../../Icons/SearchIcon";
import {ChevronDownIcon} from "../../Icons/ChevronDownIcon";
import {capitalize} from "../Finances/utils";
import {
    MEMBERSHIP_STATUS_END_OF_ROTARACTION,
    ONLY_PRES_SEC,
    PRANALI_ACCESS_VIEW_FOR_MEMBERSHIP,
    PRES_SEC_AND_IT_TEAM, PRES_SEC_CORE_AND_HIGHER
} from "../../../constants/WebPageConstants";
import {selectAllUserData} from "../../redux/slice/userSlice";
import {EditIcon, EyeIcon} from "../../Icons/Icons";
import ViewMemberData from "./ViewMemberData";
import {useNavigate} from "react-router-dom";
import {getMembershipDataById} from "../../../services/MembershipService";
import {COLOR_MAP_MEMBERSHIP_STATUS} from "../../common/TableCommonMethodsAndHelpers";

export default function MembershipTable() {
    const user = useSelector(selectAllUserData);
    const clubsList = useSelector(selectClubsList);
    const membershipList = useSelector(selectMembershipData);
    const membershipStatus = useSelector(getMembershipDataStatus);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const {isOpen, onOpen, onOpenChange} = useDisclosure();

    const INITIAL_VISIBLE_COLUMNS = ["memberName", "designationsList", "membershipStatus", "pranaliId", "actions"];
    const [filterValue, setFilterValue] = React.useState("");
    const [selectedKeys, setSelectedKeys] = React.useState(new Set([]));
    const [visibleColumns, setVisibleColumns] = React.useState(new Set(INITIAL_VISIBLE_COLUMNS));
    const [isSelected, setIsSelected] = React.useState(true);
    const [clubNameFilter, setClubNameFilter] = React.useState("all");
    const [rowsPerPage, setRowsPerPage] = React.useState(15);
    const [sortDescriptor, setSortDescriptor] = React.useState({
        column: "memberName",
        direction: "ascending",
    });
    const [page, setPage] = React.useState(1);

    const hasSearchFilter = Boolean(filterValue);

    const headerColumns = React.useMemo(() => {
        if (visibleColumns === "all") return COLUMNS_MEMBERSHIP;

        return COLUMNS_MEMBERSHIP.filter((column) => Array.from(visibleColumns).includes(column.uid));
    }, [visibleColumns]);

    const filteredItems = React.useMemo(() => {
        let filteredRecords = [...membershipList];

        if (hasSearchFilter) {
            filteredRecords = filteredRecords.filter(records =>
                records.memberName.toLowerCase().includes(filterValue.toLowerCase()),
            );
        }
        if (clubNameFilter !== "all" && Array.from(clubNameFilter).length !== clubsList.length) {
            filteredRecords = filteredRecords.filter(records =>
                Array.from(clubNameFilter).includes(records.clubName),
            );
        }

        return filteredRecords;
    }, [membershipList, filterValue, clubNameFilter]);

    const pages = Math.ceil(filteredItems.length / rowsPerPage);

    const items = React.useMemo(() => {
        const start = (page - 1) * rowsPerPage;
        const end = start + rowsPerPage;

        return filteredItems.slice(start, end);
    }, [page, filteredItems, rowsPerPage]);

    const sortedItems = React.useMemo(() => {
        return [...items].sort((a, b) => {
            let colSorterKeyArray = sortDescriptor.column.split(".");
            let first;
            let second;
            if (colSorterKeyArray.length > 1) {
                first = a[colSorterKeyArray[0]][colSorterKeyArray[1]];
                second = b[colSorterKeyArray[0]][colSorterKeyArray[1]];
            } else {
                first = a[sortDescriptor.column];
                second = b[sortDescriptor.column];
            }
            const cmp = first < second ? -1 : first > second ? 1 : 0;

            return sortDescriptor.direction === "descending" ? -cmp : cmp;
        });
    }, [sortDescriptor, items]);

    const renderCell = React.useCallback((record, columnKey) => {
        let cellValue = record[columnKey]

        switch (columnKey) {
            case "designationsList" :
                return (
                    null !== record.designationsList && undefined !== record.designationsList && record.designationsList.map(value =>
                        <Chip className="m-0.5" color="warning" variant="dot">
                            {value}
                        </Chip>
                    )
                );
            // case "dateOfBirth":
            //     return (<p>{getAge(cellValue)}</p>);
            case "pranaliAccess":
                return (
                    <Chip classNames={{
                        base: "bg-gradient-to-br from-indigo-500 to-pink-500 border-small border-white/50 shadow-pink-500/30",
                        content: "drop-shadow shadow-black text-white",
                    }} size="sm">
                        {PRANALI_ACCESS_VIEW_FOR_MEMBERSHIP.includes(cellValue) ? cellValue :
                            cellValue === "Bronze" ? "Pres-Sec" : "Council"}
                    </Chip>
                );
            case "membershipStatus":
                return (
                    <Chip className="capitalize" color={COLOR_MAP_MEMBERSHIP_STATUS[cellValue]} size="sm"
                          variant="flat">
                        {cellValue}
                    </Chip>
                );
            case "isDCM":
                return (<p>{cellValue ? "Yes" : "No"}</p>);
            case "actions":
                return (
                    <div className="relative flex items-center gap-2">
                        {PRES_SEC_CORE_AND_HIGHER.includes(user.pranaliAccess) && <Tooltip content="Details">
                            <span className="text-lg text-primary-400 cursor-pointer active:opacity-50"
                                  onClick={() => {
                                      getMembershipDataById(dispatch, user, record["membershipId"]).then(() =>
                                          onOpen()
                                      );
                                  }}>
                                <EyeIcon/>
                             </span>
                        </Tooltip>}
                        {
                            PRES_SEC_AND_IT_TEAM.includes(user.pranaliAccess) && !MEMBERSHIP_STATUS_END_OF_ROTARACTION.includes(record.membershipStatus) &&
                            <Tooltip content="Edit user">
                                <span className="text-lg text-secondary-400 cursor-pointer active:opacity-50"
                                      onClick={_ => {
                                          getMembershipDataById(dispatch, user, record["membershipId"]).then(() =>
                                              navigate("/pranali/membership/edit")
                                          );
                                      }}>
                                    <EditIcon/>
                                </span>
                            </Tooltip>
                        }
                    </div>
                );
            default:
                return cellValue;
        }
    }, []);

    const onNextPage = React.useCallback(() => {
        if (page < pages) {
            setPage(page + 1);
        }
    }, [page, pages]);

    const onPreviousPage = React.useCallback(() => {
        if (page > 1) {
            setPage(page - 1);
        }
    }, [page]);

    const onRowsPerPageChange = React.useCallback((e) => {
        setRowsPerPage(Number(e.target.value));
        setPage(1);
    }, []);

    const onSearchChange = React.useCallback((value) => {
        if (value) {
            setFilterValue(value);
            setPage(1);
        } else {
            setFilterValue("");
        }
    }, []);

    const onClear = React.useCallback(() => {
        setFilterValue("")
        setPage(1)
    }, []);

    const topContent = React.useMemo(() => {
        return (
            <div className="flex flex-col gap-4">
                <div className="grid grid-cols-6 md:grid-cols-12 justify-between gap-3">
                    <Input
                        isClearable
                        className="col-span-6 md:col-span-4 w-full"
                        placeholder="Search member by name"
                        startContent={<SearchIcon/>}
                        value={filterValue}
                        onClear={() => onClear()}
                        onValueChange={onSearchChange}
                    />
                    <div className="col-span-6 md:col-span-8 flex flex-wrap gap-3 justify-end">
                        {!ONLY_PRES_SEC.includes(user.pranaliAccess) &&
                            <>
                                <Select
                                    items={clubsList}
                                    selectionMode="multiple"
                                    label="Selected Club's"
                                    placeholder="Select a club"
                                    labelPlacement="inside"
                                    selectedKeys={clubNameFilter}
                                    onSelectionChange={setClubNameFilter}
                                    className="max-w-xs sm:min-w-sm sm:max-w-md">
                                    {clubDataListItems => (
                                        <SelectItem key={clubDataListItems.name} textValue={clubDataListItems.name}>
                                            <div className="flex gap-2 items-center">
                                                <div className="flex flex-col">
                                                    <span className="text-small">{clubDataListItems.name}</span>
                                                    <span
                                                        className="text-tiny text-default-400">Zone {clubDataListItems.zone}</span>
                                                </div>
                                            </div>
                                        </SelectItem>
                                    )}
                                </Select>
                                <Checkbox isSelected={isSelected}
                                          onValueChange={(value) => {
                                              setIsSelected(value);
                                              if (!value)
                                                  setClubNameFilter([]);
                                              else {
                                                  let tempList = [];
                                                  clubsList.forEach(club => tempList.push(club.name));
                                                  setClubNameFilter(tempList);
                                              }
                                          }}> Select All Club</Checkbox>
                            </>
                        }
                        <Dropdown>
                            <DropdownTrigger className="">
                                <Button endContent={<ChevronDownIcon className="text-small"/>} variant="flat">
                                    Columns
                                </Button>
                            </DropdownTrigger>
                            <DropdownMenu
                                disallowEmptySelection
                                aria-label="Table Columns"
                                closeOnSelect={false}
                                selectedKeys={visibleColumns}
                                selectionMode="multiple"
                                onSelectionChange={setVisibleColumns}>
                                {COLUMNS_MEMBERSHIP.map(column => (
                                    <DropdownItem key={column.uid} className="capitalize">
                                        {capitalize(column.name)}
                                    </DropdownItem>
                                ))}
                            </DropdownMenu>
                        </Dropdown>
                    </div>
                </div>
                <div className="flex justify-between items-center">
                    <span
                        className="text-default-400 text-small">Total {membershipList.length} records</span>
                    <label className="flex items-center text-default-400 text-small">
                        Rows per page:
                        <select
                            className="bg-transparent outline-none text-default-400 text-small"
                            onChange={onRowsPerPageChange}>
                            <option value="15">15</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="75">75</option>
                            <option value="100">100</option>
                        </select>
                    </label>
                </div>
            </div>
        );
    }, [
        filterValue,
        clubNameFilter,
        visibleColumns,
        onRowsPerPageChange,
        membershipList.length,
        onSearchChange,
        hasSearchFilter,
    ]);

    const bottomContent = React.useMemo(() => {
        return (
            <div className="py-2 px-2 flex justify-between items-center">
                <Pagination
                    isCompact
                    showControls
                    showShadow
                    color="primary"
                    page={page}
                    total={pages}
                    onChange={setPage}
                />
                <div className="hidden sm:flex w-[30%] justify-end gap-2">
                    <Button isDisabled={pages === 1} size="sm" variant="flat" onPress={onPreviousPage}>
                        Previous
                    </Button>
                    <Button isDisabled={pages === 1} size="sm" variant="flat" onPress={onNextPage}>
                        Next
                    </Button>
                </div>
            </div>
        );
    }, [selectedKeys, items.length, page, pages, hasSearchFilter]);

    return (
        <>
            <Table
                aria-label="Example table with custom cells, pagination and sorting"
                isHeaderSticky
                bottomContent={bottomContent}
                bottomContentPlacement="outside"
                classNames={{
                    wrapper: "min-h-[150px]",
                }}
                selectedKeys={selectedKeys}
                sortDescriptor={sortDescriptor}
                topContent={topContent}
                topContentPlacement="outside"
                onSelectionChange={setSelectedKeys}
                onSortChange={setSortDescriptor}>
                <TableHeader columns={headerColumns}>
                    {column => (
                        <TableColumn
                            key={column.uid}
                            align={"start"}
                            allowsSorting={column.sortable}>
                            {column.name}
                        </TableColumn>
                    )}
                </TableHeader>
                <TableBody emptyContent={membershipStatus === "complete" ? "No record found" : null}
                           items={sortedItems}
                           isLoading={membershipStatus !== "complete"}
                           loadingContent={membershipStatus !== "complete" ?
                               <div className="w-4/6 mt-14 flex flex-col gap-4 text-center">
                                   <Progress
                                       size="sm"
                                       isIndeterminate
                                       aria-label="Loading..."/>
                                   <p className="text-sm font-regular leading-7 text-gray-900">Please wait while we
                                       fetch the latest membership data for you 😘</p>
                               </div> : null}>
                    {item => <TableRow key={item.membershipId}>
                        {columnKey => <TableCell>{renderCell(item, columnKey)}</TableCell>}
                    </TableRow>}
                </TableBody>
            </Table>

            <Modal
                isOpen={isOpen}
                size={"3xl"}
                backdrop="blur"
                placement="auto"
                scrollBehavior="inside"
                onOpenChange={onOpenChange}>
                <ModalContent>
                    {() => (
                        <>
                            <ModalHeader/>
                            <ModalBody>
                                <ViewMemberData/>
                            </ModalBody>
                            <ModalFooter/>
                        </>
                    )}
                </ModalContent>
            </Modal>
        </>
    );
}

export function getAge(age) {
    let today = new Date();
    let birthDate = new Date(age);
    let years = today.getFullYear() - birthDate.getFullYear();
    let months = today.getMonth() - birthDate.getMonth();
    if (months < 0 || (months === 0 && today.getDate() < birthDate.getDate())) {
        years--;
    }
    return years;
}