import {
    getIsProjectListForNomination,
    getProjectReportsStatus,
    setNewReportStatus,
    setSelectedReport,
    setSelectedReportStatus,
} from "../../redux/slice/reportingSlice";
import {useDispatch, useSelector} from "react-redux";
import {selectAllUserData} from "../../redux/slice/userSlice";
import {useNavigate} from "react-router-dom";
import React, {useEffect} from "react";
import {ToastContainer} from "react-toastify";
import {Button} from "@nextui-org/react";
import {Add, Refresh} from "../../Icons/SystemIcons";
import {getProjectsList} from "../../../services/ProjectsServices";
import ProjectsTable from "./ProjectsTable";
import {ONLY_PRES_SEC_AND_IT} from "../../../constants/WebPageConstants";

export default function Projects() {
    const user = useSelector(selectAllUserData);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const projectReportStatus = useSelector(getProjectReportsStatus);
    const isProjectListForNomination = useSelector(getIsProjectListForNomination);

    useEffect(() => {
        if (projectReportStatus === 'idle' || isProjectListForNomination)
            getProjectsList(dispatch, user, false);
    }, []);

    return (
        <div className="flex flex-col gap-4 sm:gap-8">
            <ToastContainer/>
            <div className="flex justify-around gap-4 sm:gap-8 items-center">
                <div className="relative">
                    <h1 className="text-2xl sm:text-3xl font-medium">Projects</h1>
                </div>

                <div className="flex flex-col">
                    <div className="flex justify-end items-center">
                        {ONLY_PRES_SEC_AND_IT.includes(user.pranaliAccess) &&
                            <Button className="hidden sm:flex mr-2" type="button" color="success" variant="ghost"
                                    startContent={<Add fill="currentColor" size={28}/>}
                                    onClick={() => {
                                        dispatch(setSelectedReport({
                                            report: null,
                                            reportType: "Project"
                                        }));
                                        dispatch(setNewReportStatus('idle'))
                                        dispatch(setSelectedReportStatus('idle'))
                                        navigate("/pranali/projects/new-report")
                                    }}>
                                New Project Report
                            </Button>}
                        {ONLY_PRES_SEC_AND_IT.includes(user.pranaliAccess) &&
                            <Button className="mr-2 sm:hidden" type="button" isIconOnly color="success" variant="ghost"
                                    onClick={() => navigate("/pranali/projects/new-report")}>
                                <Add fill="currentColor" size={28}/>
                            </Button>}
                        <Button type="button" isIconOnly color="default" variant="ghost"
                                onClick={() => getProjectsList(dispatch, user, false)}>
                            <Refresh fill="currentColor" size={28}/>
                        </Button>
                    </div>
                </div>
            </div>
            <ProjectsTable/>
        </div>
    );
}