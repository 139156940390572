import React, {useEffect} from "react";
import {Markup} from "interweave";
import "./Meeting.css";
import "../../../styles/textColors.scss";
import "../../../styles/forms.scss";
import {useDispatch, useSelector} from "react-redux";
import {selectSelectedReport, setSelectedReport, setSelectedReportStatus} from "../../redux/slice/reportingSlice";
import {
    Image,
    Card,
    CardBody,
    CardHeader,
    Chip,
    Divider,
    CardFooter,
    Button,
    Link,
    Progress,
    Tooltip, Avatar
} from "@nextui-org/react";
import {
    CalendarIcon,
    DateAndTimeIcon,
    GroupOfPeopleIcon,
    LocationIcon,
    TagIcon
} from "../../Icons/Icons";

import IncomeCard from "../../../assets/images/financeCards/incomeCard.png";
import ExpenseCard from "../../../assets/images/financeCards/expenseCard.png";
import ProfitCard from "../../../assets/images/financeCards/profitCard.png";
import LossCard from "../../../assets/images/financeCards/lossCard.png";
import {IconWrapperSize14} from "../../Icons/IconWrapper";
import {getImageFromDrive} from "../../common/DetailsViewHelpers";

function ViewReportMeetingMinutes() {

    const selectedReport = useSelector(selectSelectedReport);
    const dispatch = useDispatch();

    const totalMembers =
        (selectedReport.attendance?.homeClubActiveMembersInfo?.length > 0 ? selectedReport.attendance.homeClubActiveMembersInfo.length : 0) +
        (selectedReport.attendance?.homeClubGuestMembersInfo?.length > 0 ? selectedReport.attendance.homeClubGuestMembersInfo.length : 0) +
        (selectedReport.attendance?.dcmsInfo?.length > 0 ? selectedReport.attendance.dcmsInfo.length : 0) +
        (selectedReport.attendance?.rotarians !== undefined ? selectedReport.attendance.rotarians : 0) +
        (selectedReport.attendance?.otherGuests !== undefined ? selectedReport.attendance.otherGuests : 0) +
        (selectedReport.attendance?.alumnus !== undefined ? selectedReport.attendance.alumnus : 0) +
        (selectedReport.attendance?.otherClubMembers !== undefined ? selectedReport.attendance.otherClubMembers : 0) +
        (selectedReport.attendance?.otherPis !== undefined ? selectedReport.attendance.otherPis : 0) +
        (selectedReport.attendance?.otherDistrictRotaractors !== undefined ? selectedReport.attendance.otherDistrictRotaractors : 0) +
        (selectedReport.attendance?.interactors !== undefined ? selectedReport.attendance.interactors : 0);

    const meetingsBasicDetailsData = [
        {
            title: "Venue",
            dynamicData: selectedReport.venue,
            icon: <IconWrapperSize14 className="bg-danger/10 text-danger">
                <LocationIcon className="text-danger" fill="currentColor" size={40}/>
            </IconWrapperSize14>
        },
        {
            title: "Start Date & Time",
            dynamicData: new Date(selectedReport.startTime.replaceAll("T", " ")).toLocaleString(),
            icon: <IconWrapperSize14 className="bg-primary/10 text-primary">
                <DateAndTimeIcon className="text-primary" fill="currentColor" size={40}/>
            </IconWrapperSize14>
        },
        {
            title: "End Date & Time",
            dynamicData: new Date(selectedReport.endTime.replaceAll("T", " ")).toLocaleString(),
            icon: <IconWrapperSize14 className="bg-warning/10 text-warning">
                <DateAndTimeIcon className="text-warning" fill="currentColor" size={40}/>
            </IconWrapperSize14>
        },
        {
            title: "Reporting Month & Quarter",
            dynamicData: selectedReport.adminData.reportingMonth + " , " + selectedReport.adminData.reportingQuarter,
            icon: <IconWrapperSize14 className="bg-warning/10 text-warning">
                <CalendarIcon className="text-warning" fill="currentColor" size={40}/>
            </IconWrapperSize14>
        },
    ]

    useEffect(() => {
        if (window.location.pathname.toString().includes("/view-report") && (null === selectedReport || undefined === selectedReport)) {
            window.opener = null;
            window.open("", "_self");
            window.close();
        }

    }, []);

    function closeMeetingsReport() {
        dispatch(setSelectedReport({
            report: null,
            reportType: null
        }));
        dispatch(setSelectedReportStatus("idle"));
        window.opener = null;
        window.open("", "_self");
        window.close();
    }

    return (<Card>
            <CardHeader className="flex gap-3">
                <div className="flex flex-col">
                    <p className="text-xl">{selectedReport.meetingType}</p>
                    <p className="text-small text-default-500"> Of {selectedReport.adminData.clubName}</p>
                    <div className="flex h-5 items-center space-x-2 text-small mt-3">
                        <Chip className="capitalize px-4"
                              color="primary"
                              size="sm"
                              variant="flat"
                              startContent={<TagIcon size={28}/>}
                        >
                            {selectedReport.meetingId}
                        </Chip>
                        <Divider orientation="vertical"/>
                        <Chip className="capitalize px-4"
                              color={"warning"}
                              size="sm"
                              variant="dot"
                              startContent={<TagIcon size={28}/>}
                        >
                            {selectedReport.adminData.reportStatus}
                        </Chip>
                    </div>
                </div>
            </CardHeader>
            <Divider/>
            <CardBody>
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
                    {meetingsBasicDetailsData.map((item, index) =>
                        <Card key={index} shadow="sm">
                            <CardBody className="flex flex-row gap-x-2 items-center">
                                {item.icon}
                                <div className="flex flex-col">
                                    <p className="text-md font-medium">{item.dynamicData}</p>
                                    <p className="text-sm text-default-500">{item.title}</p>
                                </div>
                            </CardBody>
                        </Card>
                    )}

                    <div className="order-0 col-span-full ">
                        <Image
                            width={600}
                            alt="Cover Image"
                            src={getImageFromDrive(selectedReport.supportDocuments.coverImageURL)}
                            className="reportsCoverImage col-span-full"
                        />
                    </div>

                    <div className="flex flex-col col-span-full">
                        <h2 className="text-xl font-medium mb-0.5">Minutes of the Meeting</h2>
                        <Markup content={selectedReport.minutes} className="reportTable"/>
                    </div>
                    <div className="mb-3">
                        <h2 className="text-md font-medium mb-1">To View the <Link isBlock isExternal showAnchorIcon
                                                                                   href={selectedReport.supportDocuments.supportDocumentsURL}
                                                                                   color="secondary">
                            Supporting Documents
                        </Link></h2>
                    </div>

                    <div className="flex flex-col col-span-full">
                        <h2 className="text-xl font-medium mb-1">{null !== selectedReport.eventFinances.jointEventFinances
                            ? "Home Club's Meeting Finances" : "Meeting Finances"}</h2>
                        <div
                            className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 items-center">
                            <Card>
                                <CardHeader className="absolute z-10 top-1 flex-col !items-start">
                                    <h4 className="text-white font-medium text-large">₹ {selectedReport.eventFinances.income}</h4>
                                    <p className="text-tiny text-white/60 uppercase font-bold">Income</p>
                                </CardHeader>
                                <Image
                                    removeWrapper
                                    alt="Income Card background"
                                    className="z-0 w-full h-full object-cover"
                                    src={IncomeCard}
                                />
                            </Card>
                            <Card>
                                <CardHeader className="absolute z-10 top-1 flex-col !items-start">
                                    <h4 className="text-white font-medium text-large">₹ {selectedReport.eventFinances.expense}</h4>
                                    <p className="text-tiny text-white/60 uppercase font-bold">Expense</p>
                                </CardHeader>
                                <Image
                                    removeWrapper
                                    alt="Income Card background"
                                    className="z-0 w-full h-full object-cover"
                                    src={ExpenseCard}
                                />
                            </Card>
                            <Card>
                                <CardHeader className="absolute z-10 top-1 flex-col !items-start">
                                    <h4 className="text-white font-medium text-large">₹ {selectedReport.eventFinances.profit}</h4>
                                    <p className="text-tiny text-white/60 uppercase font-bold">Profit</p>
                                </CardHeader>
                                <Image
                                    removeWrapper
                                    alt="Income Card background"
                                    className="z-0 w-full h-full object-cover"
                                    src={ProfitCard}
                                />
                            </Card>
                            <Card>
                                <CardHeader className="absolute z-10 top-1 flex-col !items-start">
                                    <h4 className="text-white font-medium text-large">₹ {selectedReport.eventFinances.loss}</h4>
                                    <p className="text-tiny text-white/60 uppercase font-bold">Loss</p>
                                </CardHeader>
                                <Image
                                    removeWrapper
                                    alt="Income Card background"
                                    className="z-0 w-full h-full object-cover"
                                    src={LossCard}
                                />
                            </Card>
                        </div>
                    </div>

                    {selectedReport.eventFinances.jointEventFinances &&
                        <div className="flex flex-col col-span-full">
                            <h2 className="text-xl font-medium mb-1">Overall Joint Meeting Finances</h2>
                            <div
                                className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 items-center">
                                <Card>
                                    <CardHeader className="absolute z-10 top-1 flex-col !items-start">
                                        <h4 className="text-white font-medium text-large">₹ {selectedReport.eventFinances.jointEventFinances.income}</h4>
                                        <p className="text-tiny text-white/60 uppercase font-bold">Overall
                                            Income</p>
                                    </CardHeader>
                                    <Image
                                        removeWrapper
                                        alt="Income Card background"
                                        className="z-0 w-full h-full object-cover"
                                        src={IncomeCard}
                                    />
                                </Card>
                                <Card>
                                    <CardHeader className="absolute z-10 top-1 flex-col !items-start">
                                        <h4 className="text-white font-medium text-large">₹ {selectedReport.eventFinances.jointEventFinances.expense}</h4>
                                        <p className="text-tiny text-white/60 uppercase font-bold">Overall
                                            Expense</p>
                                    </CardHeader>
                                    <Image
                                        removeWrapper
                                        alt="Income Card background"
                                        className="z-0 w-full h-full object-cover"
                                        src={ExpenseCard}
                                    />
                                </Card>
                                <Card>
                                    <CardHeader className="absolute z-10 top-1 flex-col !items-start">
                                        <h4 className="text-white font-medium text-large">₹ {selectedReport.eventFinances.jointEventFinances.profit}</h4>
                                        <p className="text-tiny text-white/60 uppercase font-bold">Overall
                                            Profit</p>
                                    </CardHeader>
                                    <Image
                                        removeWrapper
                                        alt="Income Card background"
                                        className="z-0 w-full h-full object-cover"
                                        src={ProfitCard}
                                    />
                                </Card>
                                <Card>
                                    <CardHeader className="absolute z-10 top-1 flex-col !items-start">
                                        <h4 className="text-white font-medium text-large">₹ {selectedReport.eventFinances.jointEventFinances.loss}</h4>
                                        <p className="text-tiny text-white/60 uppercase font-bold">Overall Loss</p>
                                    </CardHeader>
                                    <Image
                                        removeWrapper
                                        alt="Income Card background"
                                        className="z-0 w-full h-full object-cover"
                                        src={LossCard}
                                    />
                                </Card>
                            </div>
                        </div>}

                    <div className="flex flex-col col-span-full">
                        <h2 className="text-xl font-medium mb-1">Attendance</h2>
                        <div
                            className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 items-center">
                            <Card isHoverable className="col-span-full">
                                <CardBody>
                                    <div className="flex justify-end">
                                        <GroupOfPeopleIcon className="text-default" fill="currentColor" size={30}/>
                                    </div>
                                    <div className="flex flex-row gap-2 flex-wrap mb-2">
                                        {selectedReport.attendance.homeClubActiveMembersInfo.map((data, index) =>
                                            <Tooltip key={index} content={
                                                <div className="px-1 py-2">
                                                    <div className="text-small font-bold">{data.name}</div>
                                                    <div className="text-tiny">{data.pranaliId}</div>
                                                </div>
                                            }>
                                                <Chip
                                                    variant="bordered"
                                                    // color="secondary"
                                                    avatar={<Avatar
                                                        name={data.name}
                                                        getInitials={(name) => name.charAt(0)}
                                                        src={getImageFromDrive(data.profilePhotoUrl)}
                                                    />}>
                                                    {data.name}
                                                </Chip>
                                            </Tooltip>
                                        )}
                                    </div>
                                    <p className="text-sm text-default-500">Home Club Active Members</p>

                                    <Progress
                                        aria-label="Downloading..."
                                        size="sm"
                                        value={(selectedReport.attendance.homeClubActiveMembersInfo.length / totalMembers) * 100}
                                        color="secondary"
                                        className="mt-4"
                                    />
                                </CardBody>
                            </Card>
                            {selectedReport?.attendance?.homeClubGuestMembersInfo.length > 0 &&
                                <Card isHoverable className="col-span-full">
                                    <CardBody>
                                        <div className="flex justify-end">
                                            <GroupOfPeopleIcon className="text-default" fill="currentColor"
                                                               size={30}/>
                                        </div>
                                        <div className="flex flex-row gap-2 flex-wrap mb-2">
                                            {selectedReport.attendance.homeClubGuestMembersInfo.map((data, index) =>
                                                <Tooltip key={index} content={
                                                    <div className="px-1 py-2">
                                                        <div className="text-small font-bold">{data.name}</div>
                                                    </div>
                                                }>
                                                    <Chip

                                                        variant="bordered"
                                                        // color="secondary"
                                                        avatar={<Avatar
                                                            name={data.name}
                                                            getInitials={(name) => name.charAt(0)}
                                                            src={getImageFromDrive(data.profilePhotoUrl)}
                                                        />}>
                                                        {data.name}
                                                    </Chip>
                                                </Tooltip>
                                            )}
                                        </div>
                                        <p className="text-sm text-default-500">Home Club Guest Members</p>

                                        <Progress
                                            aria-label="Downloading..."
                                            size="sm"
                                            value={(selectedReport.attendance.homeClubGuestMembersInfo.length / totalMembers) * 100}
                                            color="secondary"
                                            className="mt-4"
                                        />
                                    </CardBody>
                                </Card>
                            }
                            {selectedReport?.attendance?.dcmsInfo.length > 0 &&
                                <Card isHoverable className="col-span-full">
                                    <CardBody>
                                        <div className="flex justify-end">
                                            <GroupOfPeopleIcon className="text-default" fill="currentColor"
                                                               size={30}/>
                                        </div>
                                        <div className="flex flex-row gap-2 flex-wrap mb-2">
                                            {selectedReport.attendance.dcmsInfo.map((data, index) =>
                                                <Tooltip key={index} content={
                                                    <div className="px-1 py-2">
                                                        <div className="text-small font-bold">{data.name}</div>
                                                        <div className="text-tiny">{data.pranaliId}</div>
                                                    </div>
                                                }>
                                                    <Chip
                                                        variant="bordered"
                                                        // color="secondary"
                                                        avatar={<Avatar
                                                            name={data.name}
                                                            getInitials={(name) => name.charAt(0)}
                                                            src={getImageFromDrive(data.profilePhotoUrl)}
                                                        />}>
                                                        {data.name}
                                                    </Chip>
                                                </Tooltip>
                                            )}
                                        </div>
                                        <p className="text-sm text-default-500">District Council Members</p>

                                        <Progress
                                            aria-label="Downloading..."
                                            size="sm"
                                            value={(selectedReport.attendance.dcmsInfo.length / totalMembers) * 100}
                                            color="secondary"
                                            className="mt-4"
                                        />
                                    </CardBody>
                                </Card>
                            }
                            <Card isHoverable>
                                <CardBody>
                                    <div className="flex justify-end">
                                        <GroupOfPeopleIcon className="text-default" fill="currentColor" size={30}/>
                                    </div>
                                    <div className="flex flex-col">
                                        <p className="text-lg font-medium">{selectedReport.attendance.rotarians}</p>
                                        <p className="text-sm text-default-500">Rotarians</p>
                                    </div>
                                    <Progress
                                        aria-label="Downloading..."
                                        size="sm"
                                        value={(selectedReport.attendance.rotarians / totalMembers) * 100}
                                        color="secondary"
                                        className="mt-4"
                                    />
                                </CardBody>
                            </Card>
                            <Card isHoverable>
                                <CardBody>
                                    <div className="flex justify-end">
                                        <GroupOfPeopleIcon className="text-default" fill="currentColor" size={30}/>
                                    </div>
                                    <div className="flex flex-col">
                                        <p className="text-lg font-medium">{selectedReport.attendance.otherGuests}</p>
                                        <p className="text-sm text-default-500">Other Guests</p>
                                    </div>
                                    <Progress
                                        aria-label="Downloading..."
                                        size="sm"
                                        value={(selectedReport.attendance.otherGuests / totalMembers) * 100}
                                        color="secondary"
                                        className="mt-4"
                                    />
                                </CardBody>
                            </Card>
                            <Card isHoverable>
                                <CardBody>
                                    <div className="flex justify-end">
                                        <GroupOfPeopleIcon className="text-default" fill="currentColor" size={30}/>
                                    </div>
                                    <div className="flex flex-col">
                                        <p className="text-lg font-medium">{selectedReport.attendance.alumnus}</p>
                                        <p className="text-sm text-default-500">Alumnus</p>
                                    </div>
                                    <Progress
                                        aria-label="Downloading..."
                                        size="sm"
                                        value={(selectedReport.attendance.alumnus / totalMembers) * 100}
                                        color="secondary"
                                        className="mt-4"
                                    />
                                </CardBody>
                            </Card>
                            <Card isHoverable>
                                <CardBody>
                                    <div className="flex justify-end">
                                        <GroupOfPeopleIcon className="text-default" fill="currentColor" size={30}/>
                                    </div>
                                    <div className="flex flex-col">
                                        <p className="text-lg font-medium">{selectedReport.attendance.otherClubMembers}</p>
                                        <p className="text-sm text-default-500">Other Club Members</p>
                                    </div>
                                    <Progress
                                        aria-label="Downloading..."
                                        size="sm"
                                        value={(selectedReport.attendance.otherClubMembers / totalMembers) * 100}
                                        color="secondary"
                                        className="mt-4"
                                    />
                                </CardBody>
                            </Card>
                            <Card isHoverable>
                                <CardBody>
                                    <div className="flex justify-end">
                                        <GroupOfPeopleIcon className="text-default" fill="currentColor" size={30}/>
                                    </div>
                                    <div className="flex flex-col">
                                        <p className="text-lg font-medium">{selectedReport.attendance.otherPis}</p>
                                        <p className="text-sm text-default-500">Other PIS</p>
                                    </div>
                                    <Progress
                                        aria-label="Downloading..."
                                        size="sm"
                                        value={(selectedReport.attendance.otherPis / totalMembers) * 100}
                                        color="secondary"
                                        className="mt-4"
                                    />
                                </CardBody>
                            </Card>
                            <Card isHoverable>
                                <CardBody>
                                    <div className="flex justify-end">
                                        <GroupOfPeopleIcon className="text-default" fill="currentColor" size={30}/>
                                    </div>
                                    <div className="flex flex-col">
                                        <p className="text-lg font-medium">{selectedReport.attendance.otherDistrictRotaractors}</p>
                                        <p className="text-sm text-default-500">Other District Rotaractors</p>
                                    </div>
                                    <Progress
                                        aria-label="Downloading..."
                                        size="sm"
                                        value={(selectedReport.attendance.otherDistrictRotaractors / totalMembers) * 100}
                                        color="secondary"
                                        className="mt-4"
                                    />
                                </CardBody>
                            </Card>
                            <Card isHoverable>
                                <CardBody>
                                    <div className="flex justify-end">
                                        <GroupOfPeopleIcon className="text-default" fill="currentColor" size={30}/>
                                    </div>
                                    <div className="flex flex-col">
                                        <p className="text-lg font-medium">{selectedReport.attendance.interactors}</p>
                                        <p className="text-sm text-default-500">Interactors</p>
                                    </div>
                                    <Progress
                                        aria-label="Downloading..."
                                        size="sm"
                                        value={(selectedReport.attendance.interactors / totalMembers) * 100}
                                        color="secondary"
                                        className="mt-4"
                                    />
                                </CardBody>
                            </Card>
                            <Card shadow="sm" isHoverable>
                                <CardBody>
                                    <div className="flex justify-end">
                                        <GroupOfPeopleIcon className="text-default" fill="currentColor" size={30}/>
                                    </div>
                                    <div className="flex flex-col">
                                        <p className="text-lg font-medium">{totalMembers}</p>
                                        <p className="text-sm text-default-500">Total</p>
                                    </div>
                                    <Progress
                                        aria-label="Downloading..."
                                        size="sm"
                                        value={100}
                                        color="secondary"
                                        className="mt-4"
                                    />
                                </CardBody>
                            </Card>
                        </div>
                    </div>

                    {selectedReport.isJointProject && selectedReport.jointEventClubs &&
                        <div className="flex flex-col col-span-full">
                            <h2 className="text-xl font-medium mb-1">Joint Meetings Clubs</h2>
                            <div className="flex flex-row gap-2 flex-wrap">
                                {selectedReport.jointEventClubs.map((data, index) =>
                                    <Chip
                                        key={index}
                                        variant="bordered"
                                        color="primary"
                                        radius="sm"
                                    >
                                        {data.name}
                                    </Chip>
                                )}
                            </div>
                        </div>
                    }
                </div>

            </CardBody>
            <CardFooter className="justify-end">
                <Button type="button" color="default" variant="faded" onClick={() => closeMeetingsReport()}>
                    Close
                </Button>
            </CardFooter>
        </Card>
    );

    //                 <div className="col-12 mb-3">
    //                     <h3>Cover Image :</h3>
    //                     <img
    //                         src={props.data.supportDocuments.coverImageURL}
    //                         alt="Cover Photo"
    //                         className="reportsCoverImage"
    //                     />
    //                 </div>
    //
}

export default ViewReportMeetingMinutes;
